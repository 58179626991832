<template>
  <div
    id="partenaires"
    :class="{ shop: isShop }"
    class="container-fluid"
  >
    <!-- displayed only in shop page -->
    <div
      v-if="isShop"
      id="is-shop-page"
      class="active"
    >
      <div class="big-text-bg">
        <BigText
          color="white"
          bgcolor="green"
          :title="$t('categories.title1')"
          position="left"
        />
        <div class="text-bg-un-produit">
          <BigText
            color="white"
            bgcolor="green"
            :title="$t('categories.title2')"
            position="left"
          />
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12 search-form">
            <!-- <Searchform button-color="yellow" /> -->
          </div>
        </div>
      </div>
    </div>
    <!-- END -->

    <div class="row">
      <h2>{{ title || $t('categories.ours') }}</h2>
    </div>
    <div ref="container" class="container">
      <div class="row partenaires-list">
        <NosCategoriesItem
          v-for="category in categories"
          :key="category.code"
          :category-name="category.code"
          :category="category"
          :active.sync="active"
          :container-top="top"
          :use-picto="isShop"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BigText from '@/components/UI/BigText.vue'
import NosCategoriesItem from '@/components/Categories/NosCategoriesItem'

export default {
  name: 'NosCategories',
  components: {
    BigText,
    NosCategoriesItem
  },
  props: {
    partnerId: {
      type: String
    },
    isShop: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      active: null,
      top: 0
    }
  },
  computed: {
    categories() {
      return this.$store.getters['partner/categories']
    }
  },
  mounted() {
    this.$nextTick(() => {
      const container = this.$refs.container.getBoundingClientRect()
      this.top = container.top
    })
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      return this.$store.dispatch('partner/categories', { id: this.partnerId })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

/* watermark and search form in Shop Page */
#is-shop-page:not(.active) {
  display: none;
}

#partenaires.shop {
  background-color: var(--green-color) !important;
}

.big-text-bg {
  z-index: -1;
}

.big-text-bg h2{
  font-size: 50px !important;
  top: 65px;

  @include media-breakpoint-up(md) {
    font-size: 80px !important;
  }
  @include media-breakpoint-up(lg) {
    font-size: 120px !important;
  }
}
.text-bg-un-produit h2{
  top: 115px;
  @include media-breakpoint-up(md) {
    top: 140px;
  }
  @include media-breakpoint-up(lg) {
    top: 190px;
  }
}

#partenaires .search-form {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 65px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 100px;
  }
}
/* END watermark and search form in Shop Page */

#partenaires {
  background-color: var(--yellow-color);
  position: relative;
  padding-top: 50px;
  @include media-breakpoint-up(md) {
    padding-top: 80px;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 100px;
    padding-bottom: 50px;
  }
}

h2 {
  font-size: 30px;
  font-weight: 900;
  color: white;
  text-transform: uppercase;
  padding-bottom: 40px;
  z-index: 1;
  @include media-breakpoint-up(md) {
    font-size: 40px;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 80px;
  }
}

.partenaires-list {
  position: relative;
}

</style>
