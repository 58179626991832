<template>
  <div id="decouvrir-un">
    <div v-show="!hide.tag && !hide.selection" class="big-text-bg">
      <BigText
        color="green"
        :title="$t('discover.title1')"
        position="left"
        class="mt-md-4"
      />
      <div class="text-bg-un">
        <BigText
          color="green"
          :title="$t('discover.title2')"
          position="left"
        />
      </div>
    </div>
    <div v-if="!hide.tag" class="pb-md-5 mb-5 pt-4 pt-md-5 mt-md-5">
      <DecouvrirParTag :partner-id="partnerId" @loaded="d => onLoaded('tag', d)" />
    </div>
    <SelectionSlider v-if="!hide.selection" :partner-id="partnerId" color="yellow" @loaded="d => onLoaded('selection', d)" />
  </div>
</template>

<script>
import BigText from '@/components/UI/BigText.vue'
import DecouvrirParTag from '@/components/Sliders/DecouvrirParTag.vue'
import SelectionSlider from '@/components/Sliders/SelectionSlider.vue'

export default {
  name: 'Decouvrir',
  components: {
    BigText,
    DecouvrirParTag,
    SelectionSlider
  },
  props: {
    partnerId: { type: String }
  },
  data: () => ({
    hide: {
      tag: false,
      selection: false
    }
  }),
  computed: {
    filters() {
      return this.$store.getters['partner/filters']
    }
  },
  watch: {
    'filters.length'(v) {
      this.hide.tag = !v
    }
  },
  mounted() {
    this.hide.tag = !this.filters.length
  },
  methods: {
    onLoaded(type, arry) {
      this.hide[type] = !arry.length
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

.big-text-bg h2{
  font-size: 50px;
  @include media-breakpoint-up(md) {
    font-size: 90px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 140px;
  }
}

.text-bg-un h2{
  top:50px;
  @include media-breakpoint-up(md) {
    top:100px;
  }
  @include media-breakpoint-up(lg) {
    top:160px;
  }
}

#decouvrir-un {
  position: relative;
  padding-top: 100px;
  padding-bottom: 120px;
  @include media-breakpoint-up(lg) {
    padding-bottom: 200px;
  }
}

h2 {
  font-weight: 900;
  font-size: 50px;
}
</style>
