<template>
  <div>
    <TopBanner :partner-id="partnerId" />
    <NosCategories
      :title="$t('city.shops')"
      :partner-id="partnerId"
      :is-shop="true"
    />
    <Decouvrir :partner-id="partnerId" />
  </div>
</template>

<script>
import TopBanner from '@/components/TopBanner.vue'
import NosCategories from '@/components/Categories/NosCategories.vue'
import Decouvrir from '@/components/Sliders/Decouvrir.vue'

export default {
  name: 'ViewShop',
  components: {
    TopBanner,
    NosCategories,
    Decouvrir
  },
  props: {
    partnerId: { type: String }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/youpaq.scss';

/* component Slider */
#slider {
  background: white !important;
}
#slider .slide-item .slide-icon .acheter-local {
  background: url('~@/assets/youpaq-label-new-green.png') no-repeat !important;
  background-size: 53% !important;
  width: 180px;
  height: 180px;

  @include media-breakpoint-up(md) {
      background-size: 80% !important;
  }

  @include media-breakpoint-up(lg) {
      background-size: contain;
      width: 300px;
      height: 300px;
  }
}
#slider .slide-content {
  background-color: var(--yellow-color) !important;
}
#slider .slide-content-text {
  color: white !important;
}

#slider .agile .agile__nav-button {
  background: transparent !important;
  border: none;
  position: absolute;
  bottom: 0px;
  @include media-breakpoint-up(md) {
    bottom: 240px;
  }
  @include media-breakpoint-up(lg) {
    bottom: 325px;
  }
}
#slider .agile__actions {
  position: relative;
}
#slider .agile__nav-button--prev {
  display: none;
  left: calc(50vw - 150px);
  @include media-breakpoint-up(md) {
    display: flex;
    left: calc(50vw - 385px);
  }
  @include media-breakpoint-up(lg) {
    display: flex;
    left: calc(50vw - 700px);
  }
}
#slider .agile__actions .prev {
  background: url("~@/assets/slider-prev-yellow.svg") no-repeat;
}
#slider .agile__nav-button--next {
  display: none;
  left: calc(50vw + 90px);
  @include media-breakpoint-up(md) {
    display: flex;
    left: calc(50vw + 305px);
  }
  @include media-breakpoint-up(lg) {
    display: flex;
    left: calc(50vw + 570px);
  }
}
#slider .agile__actions .next {
  background: url("~@/assets/slider-next-yellow.svg") no-repeat;
}

#slider .agile__dots {
  position: absolute;
  left: calc(50vw - 53px);
  bottom: 0px;
}
#slider .agile__dots .agile__dot button{
  background-color: transparent;
  width: 15px !important;
  height: 15px !important;
  margin: 10px;
  background-size: contain !important;
  border: none;
  background: url("~@/assets/slider-dot.svg") no-repeat;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2)) !important;
  cursor: pointer;
}
#slider .agile__dot--current button{
  background: url("~@/assets/slider-dot-current.svg") no-repeat !important;
}

/* selection */
.selection-decorator-box {
  @include media-breakpoint-up(lg) {
    border: 15px solid var(--green-color) !important;
  }
}

/* Nos categories popup*/
.cat-shops-modal .carousel-3d-slider {
  min-width: 300px !important;
  width: 300px !important;
}
</style>
