<template>
  <div
    class="selection"
    :color="color"
  >
    <div class="selection-decorator-box">
      <h2>{{ $t('selection.title') }}</h2>
    </div>
    <carousel-3d
      v-if="products.length"
      :clickable="false"
      :controls-visible="true"
      :width="300"
      :height="550"
      :display="5"
      :space="250"
      :perspective="0"
      :inverse-scaling="100"
    >
      <template v-for="(product, index) in products">
        <slide :key="product.id" :index="index">
          <ProduitItem
            :shop-id="product.shop"
            :shop-object="shops[product.shop]"
            :product-id="product.id"
            :product="product"
            :offer="product.reseller_offers[0]"
            :image="product.images && product.images.raw.url"
            :favourite.sync="product.favourite"
          />
        </slide>
      </template>
    </carousel-3d>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'
import ProduitItem from '@/components/Produits/ProduitItem.vue'

export default {
  name: 'SelectionSlider',
  components: {
    Carousel3d,
    Slide,
    ProduitItem
  },
  props: {
    partnerId: { type: String },
    color: { type: String }
  },
  data: () => ({
    loading: false,
    products: [],
    shops: []
  }),
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      return Promise
        .all([
          this.$store.dispatch('partner/selectionProducts', { id: this.partnerId }),
          this.$store.dispatch('partner/shops', { id: this.partnerId })
        ])
        .then(res => {
          this.products = res[0]
          this.shops = res[1].reduce((acc, shop) => {
            acc[shop.id] = shop
            return acc
          }, {})
          this.$emit('loaded', this.products)
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

/* Overwritten Carousel 3d style*/
.carousel-3d-container {
  margin-top: 70px !important;
  padding-top: 10px;
  height: 479px !important;

  @include media-breakpoint-up(md) {
    margin-top: 70px !important;
    padding-top: 10px;
    height: 500px !important;
  }
}

.carousel-3d-slide {
  border: none !important;
  background: transparent !important;
  overflow: visible !important;
}

.selection .carousel-3d-slide:not(.current) {
  @include media-breakpoint-down(lg) {
    opacity:abs($number: 0.1) !important;
  }
  @include media-breakpoint-down(md) {
    display:none;
  }
}

::v-deep .prev,
::v-deep .next {
  color: transparent !important;
  width: 70px !important;
  height: 70px !important;
  background-size: contain !important;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.6)) !important;
  top: -70px;
}
::v-deep .prev {
  background: url("~@/assets/slider-prev-yellow.svg") no-repeat;
  left: 5px;
  @include media-breakpoint-up(md) {
    left: calc(50vw - 220px) !important;
  }
  @include media-breakpoint-up(lg) {
    left: calc(15% - 180px) !important;
  }
}

::v-deep .next {
  background: url("~@/assets/slider-next-yellow.svg") no-repeat;
  background-position-x: right;
  right: 5px;
  @include media-breakpoint-up(md) {
    right: calc(50vw - 220px) !important;
  }
  @include media-breakpoint-up(lg) {
    right: calc(15% - 180px) !important;
  }
}

.selection {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;

  @include media-breakpoint-up(lg) {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
  }
}

.selection-decorator-box {
  position: absolute;
  min-height: 500px;
  display: flex;
  justify-content: center;
  width: 100%;

  @include media-breakpoint-up(lg) {
    min-height: 550px;
    width: 54% !important;
    margin: 0 auto;
    border: 15px solid var(--yellow-color);
    position: absolute;
  }

  h2 {
    position: absolute;
    font-weight: 900;
    font-size: 30px;
    text-transform: uppercase;
    top: -20px;
    z-index: 2;
    padding: 0 20px;

    @include media-breakpoint-up(md) {
      top: 0;
      font-size: 35px;
    }

    @include media-breakpoint-up(lg) {
      background-color: white;
      font-size: 40px;
      padding: 0 20px;
      top: -35px;
    }
  }
}

a {
  text-decoration: none;
}
</style>
