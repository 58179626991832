<template>
  <Slider :partner-id="partnerId" />
</template>

<script>
import Slider from '@/components/Sliders/Slider'

export default {
  name: 'TopBanner',
  components: { Slider },
  props: {
    partnerId: { type: String }
  }
}
</script>
