<template>
  <div
    id="envie-slider"
    class="container"
  >
    <div class="envie-decorator-box">
      <h2>{{ $t('enviede.title') }}</h2>
    </div>
    <carousel-3d
      v-if="filters.length"
      :disable3d="true"
      :clickable="false"
      :controls-visible="true"
      :height="240"
      :display="3"
      :space="400"
    >
      <template v-for="(filter, index) in filters">
        <slide :key="filter.id" :index="index">
          <div
            class="envie-list-item"
            :style="getStyle(filter)"
            @click="showProducts(filter)"
          >
            <h3>{{ filter.getName($l) }}</h3>
          </div>
        </slide>
      </template>
    </carousel-3d>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'
export default {
  name: 'DecouvrirParTag',
  components: {
    Carousel3d,
    Slide
  },
  computed: {
    filters() {
      return this.$store.getters['partner/filters']
    }
  },
  methods: {
    getStyle(filter) {
      if (!filter.images?.raw?.url) {
        return {}
      }
      return {
        backgroundImage: `url('${this.$resize(filter.images.raw.url, { w: 392, h: 312, f: 'outside' })}')`
      }
    },

    showProducts(filter) {
      this.$router.push({ name: 'enviede', params: { filterId: filter.id } })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

/* Overwritten Carousel 3d style*/
/* Overwritten Carousel 3d style*/
.carousel-3d-container {
  @include media-breakpoint-up(lg) {
    margin-top: 60px !important;
  }
}

.carousel-3d-slide {
  border: none !important;
  background: transparent !important;
  overflow: visible !important;
}

#envie-slider .carousel-3d-slide:not(.current) {
  @include media-breakpoint-down(lg) {
    opacity:abs($number: 0.1) !important;
  }
  @include media-breakpoint-down(md) {
    display:none;
  }
}

::v-deep .prev,
::v-deep .next {
  color: transparent !important;
  width: 70px !important;
  height: 70px !important;
  background-size: contain !important;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.6)) !important;
  top: 0;
}
::v-deep .prev {
  background: url("~@/assets/slider-prev-yellow.svg") no-repeat;
  left: 5px;
  @include media-breakpoint-up(md) {
    left: calc(50vw - 300px);
  }
  @include media-breakpoint-up(lg) {
    left: 0px !important;
  }
}

::v-deep .next {
  background: url("~@/assets/slider-next-yellow.svg") no-repeat;
  background-position-x: right;
  right: 5px;
  @include media-breakpoint-up(md) {
    right: calc(50vw - 300px);
  }
  @include media-breakpoint-up(lg) {
    right: 0 !important;
  }
}

#envie-slider {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;

  @include media-breakpoint-up(lg) {
    margin: 75px auto;
  }
}

.envie-decorator-box {
  position: absolute;
  min-height: 365px;
  display: flex;
  justify-content: center;
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: 54% !important;
    margin: 0 auto;
    border: 15px solid var(--green-color);
    position: absolute;
  }

  h2 {
    position: absolute;
    font-weight: 900;
    font-size: 30px;
    text-transform: uppercase;
    top: -20px;
    z-index: 0;

    @include media-breakpoint-up(md) {
      font-size: 35px;
    }

    @include media-breakpoint-up(lg) {
      background-color: white;
      font-size: 40px;
      padding: 0 20px;
      top: -35px;
    }
  }
}

.envie-list-item {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 26% 50%;
  cursor: pointer;

  @include media-breakpoint-up(md) {
    margin: 0;
  }
}
.envie-list-item h3 {
  font-weight: 900;
  color: white;
  font-size: 35px;
  text-shadow: -3px 2px 11px #000000;
  text-transform: uppercase;
  min-width: 230px;
  text-align: center;
}

</style>
