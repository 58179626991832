<template>
  <div
    ref="container"
    class="col-6 col-lg-3 mb-5 pb-md-5 partenaire"
  >
    <div
      class="partenaire-item"
      @click="show"
    >
      <img
        class="black-icons"
        :src="getImageUrl(category)"
      />
      <div class="partenaire-name">
        {{ category.getTitle($l) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NosCategoriesItem',
  props: {
    category: { type: Object },
    categoryName: { type: String },
    usePicto: { type: Boolean }
  },
  methods: {
    getImageUrl(category) {
      const img = this.usePicto ? category?.picto?.url : category?.image?.url
      if (!img) {
        return null
      }
      return this.$resize(img, { w: 322, h: 290, f: 'cover' })
    },
    show() {
      this.$store.commit('modal/show', {
        component: 'NosCategoriesItemPopup',
        params: {
          categoryName: this.categoryName
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

h2 {
  font-size: 30px;
  font-weight: 900;
  color: white;
  text-transform: uppercase;
  padding-bottom: 40px;
  @include media-breakpoint-up(md) {
    font-size: 40px;
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 80px;
  }
}

.partenaire-item {
  background-image: url('~@/assets/fond-partenaire.svg');
  filter: drop-shadow( 2px 2px 2px rgba(0, 0, 0, .6));
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10% 50%;

  .partenaire-name {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 900;
    line-height: 20px;
    padding: 0px 10px 10px 10px;
    min-width: 150px;
  }

  img {
    padding: 10px;
    max-height: 70px;
    box-sizing: border-box;
    margin-top: 20px;
    @include media-breakpoint-up(md) {
    max-height: 90px;
  }
  }
}
.partenaires-pp {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: -1;
}

.partenaires-pp:not(.active) {
  transform: scale(0);
}

.partenaire.active {
  z-index: 1;

  .partenaire-item {
    z-index: 1000;
    background-image: url('~@/assets/fond-partenaire-grey.svg') !important;
    color: white;

    .white-icon {
      display: block !important;
      z-index: 1001;
    }
    .black-icon {
      display: none !important;
    }
  }
}

.partenaire-item .white-icon {
  display: none;
}

</style>
